class Header {
  constructor() {
    this.selectors = {
      header: '.header',
      searchToggler: '#open-search',
      searchArea: '.header__search',
      mobileNavToggler: '#nav-toggle',
      nav: '.header__nav',
      navItem: '.nav__item',
      navItemButton: '.nav__item > .button:not(#nav-login)',
      skipToNavLink: '#nav-skip',
      searchInput: '.header input[type="search"]',
      resetButton: '.header input[type="reset"]',
    };
  }

  skipToNav() {
    $(this.selectors.skipToNavLink).on('click', (event) => {
      event.preventDefault();

      $(this.selectors.navItemButton).eq(0).focus().click();
    });
  }

  resetNav() {
    // hide nav, close items when interacting with search button
    $(this.selectors.nav).removeClass('show-nav');
    $(this.selectors.header).removeClass('header--nav-open header--nav-items-active');
    $(this.selectors.navItem).removeClass('nav__item--active')
      .find('.nav__item-content').slideUp();
    $(this.selectors.navItemButton).attr('aria-expanded', 'false');
    $('body').removeClass('lock-scroll');

    // change mobile nav toggler icon
    $(this.selectors.mobileNavToggler).removeClass('close');
    $(this.selectors.mobileNavToggler).addClass('burger');
  }

  toggleSearchArea() {
    $(this.selectors.searchToggler).on('click', () => {
      $(this.selectors.searchInput).focus();
      $(this.selectors.searchArea).toggleClass('show-search');
      $(this.selectors.header).toggleClass('header--search-open');

      /* eslint no-unused-expressions:
      ["error", { "allowShortCircuit": true, "allowTernary": true }] */
      $(this.selectors.searchArea).hasClass('show-search')
        ? $(this.selectors.searchInput).focus()
        : $(this.selectors.searchInput).blur();

      this.resetNav();
    });
  }

  handleNavClearInput() {
    // native input type search doesn't work on Firefox
    // using input type="reset"
    $(this.selectors.searchInput).on('keydown', () => {
      $(this.selectors.resetButton).show();
    });

    $(this.selectors.resetButton).on('click', (e) => {
      $(e.currentTarget).hide();
    });
  }

  toggleNavMobile() {
    $(this.selectors.mobileNavToggler).on('click', (event) => {
      $(this.selectors.nav).toggleClass('show-nav');
      $(this.selectors.header).toggleClass('header--nav-open');
      $(event.currentTarget).toggleClass('buger close');
      $('body').toggleClass('lock-scroll');

      // hide search when nav is open
      $(this.selectors.searchArea).removeClass('show-search');
      $(this.selectors.header).removeClass('header--search-open');
    });
  }

  toggleSecondLevel() {
    $(this.selectors.navItemButton).on('click', (event) => {
      event.preventDefault();
      // close other open items (one item open at a time)
      $(event.currentTarget).closest('.nav__item').toggleClass('nav__item--active')
        .siblings('.nav__item--active').removeClass('nav__item--active')
        .find('.nav__item-content').slideUp();

      $(event.currentTarget)
        .closest('.nav__item')
        .find('.nav__item-content').slideToggle();

      $(event.currentTarget)
        .closest('.nav__item')
        .siblings()
        .find('.button[aria-expanded]').attr('aria-expanded', 'false');

      const isExpanded = $(event.currentTarget).attr('aria-expanded');
      /* eslint no-unused-expressions:
      ["error", { "allowShortCircuit": true, "allowTernary": true }] */
      isExpanded === 'true'
        ? $(event.currentTarget).attr('aria-expanded', 'false')
        : $(event.currentTarget).attr('aria-expanded', 'true');

      if ($('.nav__item--active').length > 0) {
        $(this.selectors.header).addClass('header--nav-items-active');

        // desktop only
        if (window.matchMedia('(min-width: 1220px)').matches) {
          $('body').addClass('lock-scroll');
        }

        // hide search when nav items are active
        $(this.selectors.searchArea).removeClass('show-search');
        $(this.selectors.header).removeClass('header--search-open');
      } else {
        $(this.selectors.header).removeClass('header--nav-items-active');

        // desktop only
        if (window.matchMedia('(min-width: 1220px)').matches) {
          $('body').removeClass('lock-scroll');
        }
      }
    });
  }

  resizeReset() {
    $(window).on('resize', () => {
      if ($(window).innerWidth() >= 1220) {
        this.resetNav();
      }
    });
  }

  init() {
    this.skipToNav();
    this.toggleSearchArea();
    this.handleNavClearInput();
    this.toggleNavMobile();
    this.toggleSecondLevel();
    this.resizeReset();
  }
}

export default new Header();
