import '../scss/main.scss';
// bootstrap js not used
// import 'bootstrap/dist/js/bootstrap.bundle';

// only import global components modules
import Header from './components/Header';

/**
 * Identifiers - A method to link the JS to HTML.
 * If the HTML exists on screen, the JS should load to accompany it
 */
const identifiers = {
  accordion: '.accordion',
  copyLink: '.copy-link',
  header: '.header',
  filters: '.filters__form',
  imageGallery: '.imageGallery',
  inPageNavigation: '.inPageNavigation',
  pageUseful: '.pageUseful',
  printGuide: 'body[data-print="true"]',
  searchHero: '.heroRow--search',
  selectInput: 'select',
  tabs: '.tab-list',
  video: '.video',
  myTurn2usForm: '.myt2uForm',
  autocomplete: '.myt2uForm__field--autocomplete input[type="search"]',
  deleteClient: '.button.delete-client',
  showClientName: 'button.show-client-name',
  updateDetailsTabs: '.ud-summary .myt2uForm__footer .button',
};

async function AccordionImport() {
  if ($(identifiers.accordion).length) {
    const module = await import('./components/Accordion');
    module.default.init(identifiers.accordion);
  }
}

async function CopyLinkImport() {
  if ($(identifiers.copyLink).length) {
    const module = await import('./components/CopyLink');
    module.default.init(identifiers.copyLink);
  }
}

async function FiltersImport() {
  if ($(identifiers.filters).length) {
    const module = await import('./components/Filters');
    module.default.init(identifiers.filters);
  }
}

async function ImageGalleryImport() {
  if ($(identifiers.imageGallery).length) {
    const module = await import('./components/ImageGallery');
    module.default.init(identifiers.imageGallery);
  }
}

async function InPageNavigationImport() {
  if ($(identifiers.inPageNavigation).length) {
    const module = await import('./components/InPageNavigation');
    module.default.init(identifiers.inPageNavigation);
  }
}

async function PageUsefulImport() {
  if ($(identifiers.pageUseful).length) {
    const module = await import('./components/PageUseful');
    module.default.init(identifiers.pageUseful);
  }
}

async function PrintGuideImport() {
  if ($(identifiers.printGuide).length) {
    const module = await import('./components/PrintGuide');
    module.default.init(identifiers.printGuide);
  }
}

async function SearchHeroImport() {
  if ($(identifiers.searchHero).length) {
    const module = await import('./components/SearchHero');
    module.default.init(identifiers.searchHero);
  }
}

async function SelectInputImport() {
  if ($(identifiers.selectInput).length) {
    const module = await import('./components/Select');
    module.default.init(identifiers.selectInput);
  }
}

async function TabsImport() {
  if ($(identifiers.tabs).length) {
    const module = await import('./components/Tabs');
    module.default.init(identifiers.tabs);
  }
}

async function VideoImport() {
  if ($(identifiers.video).length) {
    const module = await import('./components/Video');
    module.default.init(identifiers.video);
  }
}

/* MyTurn2us imports */
async function MyTurn2usFormImport() {
  if ($(identifiers.myTurn2usForm).length) {
    const module = await import('./components/MyTurn2usForm');
    module.default.init(identifiers.myTurn2usForm);
  }
}

async function AutocompleteImport() {
  if ($(identifiers.autocomplete).length) {
    const module = await import('./components/Autocomplete');
    module.default.init(identifiers.autocomplete);
  }
}

async function DeleteClientImport() {
  if ($(identifiers.deleteClient).length) {
    const module = await import('./components/DeleteClient');
    module.default.init(identifiers.deleteClient);
  }
}

async function ShowClientNameImport() {
  if ($(identifiers.showClientName).length) {
    const module = await import('./components/ShowClientName');
    module.default.init(identifiers.showClientName);
  }
}

async function UpdateDetailsTabsImport() {
  if ($(identifiers.updateDetailsTabs).length) {
    const module = await import('./components/UpdateDetailsTabs');
    module.default.init(identifiers.updateDetailsTabs);
  }
}

$(() => {
  /**
   * Import site wide modules
   * These modules should always load on every page
   */
  Header.init(identifiers.header);

  /**
   * Import component specific modules
   * These modules will only load if the relevant HTML identifier exists
   */
  AccordionImport();
  CopyLinkImport();
  FiltersImport();
  ImageGalleryImport();
  InPageNavigationImport();
  PageUsefulImport();
  PrintGuideImport();
  SearchHeroImport();
  SelectInputImport();
  TabsImport();
  VideoImport();

  /* MyTurn2us imports */
  MyTurn2usFormImport();
  AutocompleteImport();
  DeleteClientImport();
  ShowClientNameImport();
  UpdateDetailsTabsImport();
});
